import React from 'react'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'


const NotFoundPage = ({data}) => (
  <Layout>
      <div className="primary-contain">
      <Hero headerimg={data.headerimage.childImageSharp.fluid}/>
      <div className="secondary-contain">
      <section className="columns">
        <div className="column is-8 is-offset-2 has-text-centered">
        <h1 className="err-title down-2"><span>404</span><br/>We Couldn't Find That Page </h1>
        <p className='spread-3'>Click the link below to go back to the home page.</p>
        <Link to="/" className="button is-primary" > Back To Home Page </Link>
        </div>
      </section>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage

export const errorPageQuery = graphql `
  query errorPage {
    headerimage: file(relativePath: {eq: "composite2.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
